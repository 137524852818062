<template>
  <div
    v-if="!$apollo.queries.dashboardConfig.loading"
    class="dashboard-page"
  >
    <div class="dashboard-page__title">
      <h1 class="ellipsis">{{ title }}</h1>
    </div>
    <div class="dashboard-list">
      <div
        v-for="(graphConfig, idx) in dashboardData"
        :key="idx"
        :style="graphStyles(graphConfig)"
        class="dashboard_item"
      >
        <component
          :is="graphComponent(graphConfig?.type)"
          :vIf="graphConfig?.type"
          :options="graphConfig?.options"
          :data="graphConfig?.data"
        />
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import CONFIG_QUERY from '@/queries/config';
import { XHR, parseJson } from '@/helpers';

import {
  Bar,
  Line as LineChart,
  Doughnut,
  Pie,
  PolarArea,
  Radar,
  Bubble,
  Scatter,
} from 'vue-chartjs';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  RadialLinearScale,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  RadialLinearScale,
);

export default {
  name: 'DashboardPage',
  components: { Bar, Doughnut, LineChart, Pie, PolarArea, Radar, Bubble, Scatter },

  apollo: {
    dashboardConfig: {
      ...CONFIG_QUERY,
      variables() {
        return {
          name: `${this.pageConfig.type}_${this.pageConfig.code}`,
        };
      },
      update({ table }) {
        return table.documents[0].data?.config;
      },
      error(error) {
        this.emitError(this.$t('app.error.mainConfig'), error.message);
      },
    },
  },
  data() {
    return {
      dashboardData: [],
    };
  },

  computed: {
    pageConfig() {
      return store.state.activeSidebarItem || {};
    },
    title() {
      return this.dashboardConfig?.title ?? this.pageConfig.code;
    },
  },
  watch: {
    dashboardConfig: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.loadDashboards(newVal.dashboards);
        }
      },
    },
  },
  methods: {
    async loadDashboards(dashboards) {
      Promise.all(
        dashboards.map(
          (el) =>
            new Promise((resolve) => {
              if (el?.restUrl) {
                XHR.get(el.restUrl, { absoluteUrl: true }).then(
                  (response) => {
                    const data = parseJson(response);
                    resolve(data);
                  },
                  () => {
                    this.emitError(`Failed to load data from ${el.restUrl}`);

                    resolve({});
                  },
                );
              } else {
                resolve(el);
              }
            }),
        ),
      ).then((results) => {
        this.dashboardData = results;
      });
    },
    graphComponent(type) {
      return (
        {
          bar: 'bar',
          line: 'line-chart',
          doughnut: 'doughnut',
          pie: 'pie',
          polarArea: 'polar-area',
          radar: 'radar',
          bubble: 'bubble',
          scatter: 'scatter',
        }[type] ?? 'bar'
      );
    },
    graphStyles(graphConfig) {
      return {
        gridColumn: `span ${graphConfig?.width === 50 ? '1' : '2'}`,
      };
    },
  },
};
</script>

<style lang="scss">
.dashboard-page {
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x: visible;

  &__title {
    position: relative;
    flex-basis: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    height: 42px;

    h1 {
      display: inline-block;
      font-size: 28px;
      font-weight: 700;
      vertical-align: sub;
      margin-bottom: 0;
      margin-right: 30px;
    }
  }

  .dashboard-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    overflow: hidden;
    gap: 40px 20px;
  }
}

@media (max-width: $desktopBreakpoint) {
  .dashboard-page .dashboard-list {
    grid-template-columns: 1fr;

    .dashboard-item {
      grid-column: span 1;
    }
  }
}

@media (min-width: $desktopBreakpoint) {
  .dashboard-page {
    @include scrollbars();
    height: 100%;
    overflow-y: scroll;

    &.noscroll {
      overflow-y: hidden;
    }
  }

  .menu--vertical .dashboard-page {
    padding-left: 40px;
    padding-right: 26px;

    &.noscroll {
      padding-right: 34px;
    }
  }

  .menu--horizontal .dashboard-page {
    padding-left: 24px;
    padding-right: 24px;

    &.noscroll {
      padding-right: 32px;
    }
  }
}
</style>
